<template>
  <div class="box-items">
    <div class="box-item">
      <img src="@/assets/image/cn/homepagebgd.png" alt="">
      <div class="box-body">
        <div class="item1">
          <img style="width: 209px;margin-right: 20px" src="@/assets/image/cn/beefuser.png">
          <div  v-for="(item,index) in userInfo" :key="index" >
            <div>{{ item. label }}</div>
            <div>
              <img :src=item.icon alt="">
              <countTo :startVal='item.startVal' :endVal='item.endVal' :duration='1800'></countTo>
              <span>+</span>
            </div>
          </div>
        </div>
        <div class="division"/>
        <div class="item2">
          <img src="@/assets/image/homepage/logo.png" alt="">
          <div>必孚（中国）</div>
          <div>进口牛肉行业第三方平台</div>
        </div>
        <div class="item3">
          <div>
            <p style="text-align: justify">必孚（中国），亦称“Beef to China (BTC)”，成立于2014年，陆续在中国成都、加拿大埃德蒙顿、智利圣地亚哥和巴西圣保罗设立代表处，是帮助实现中国牛肉买家与境外牛肉出口商对接的行业大数据服务提供商和第三方现期货价格指数及交易平台。</p>
            <p style="text-align: justify">基于中国牛肉消费市场的庞大需求，进口牛肉在2015-2020年的过去5年间进入了初期发展阶段的井喷期，正关进口量从47万吨上升至超212万吨，经中国海关总署准入的牛肉输华国从9个增加至27个，获批的境外牛肉输华工厂从129家扩大至559家，申请并获批肉类进口资质的中国进口商从1000余家增长至超4000家，且呈持续上升的发展趋势。</p>
          </div>
          <div>
            <p style="text-align: justify">必孚作为进口牛肉行业第三方平台始终以行业发展和社会福祉为己任，成立至今,必孚用户涵盖中国以及巴西、阿根廷、乌拉圭、澳大利亚、新西兰在内的27个中国海关总署准入的牛肉输华国家，境外输华准入工厂覆盖率达80%，中国进口商覆盖率达90%，政企性质用户数量超2万，包括中国海关等中国官方单位、国外使领馆、国外官方协会与机构、外资企业、金融机构、央企国企以及不同规模的民营企业；行业群体涉及全产业链参与者，具体囊括国外农牧场、肉类屠宰加工厂、出口商、贸易商和国内进口商、代理商、加工厂、贸易商、批发商/档口、物流仓储、商超电商及餐饮终端。</p>
          </div>
        </div>
      </div>
    </div>
    <img class="img-body" src="@/assets/image/cn/homepage.png" alt="">
  </div>
</template>

<script>
import countTo from 'vue-count-to'

export default {
  name: 'HomePage',
  components: { countTo },
  data () {
    return {
      userInfo: [
        { label: '辐射国家数', icon: require('@/assets/image/homepage/world.png'), startVal: 0, endVal: 27 },
        { label: '国内用户数', icon: require('@/assets/image/homepage/china.png'), startVal: 0, endVal: 20000 },
        { label: '海外用户数', icon: require('@/assets/image/homepage/global.png'), startVal: 0, endVal: 5000 }
      ]
    }
  }
}
</script>

<style scoped>

@media screen and (width: 1366px) {
  .img-body {
    width: 1366px;
  }
  .box-item {
    width: 1366px;
    position: relative;
  }
}

@media screen and (min-width: 1920px) {
  .img-body {
    width: 1920px;
  }
  .box-item {
    width: 1920px;
    position: relative;
  }
}

@media screen and (min-width: 2048px) {
  .img-body {
    width: 2560px;
  }
  .box-item {
    width: 2560px;
    position: relative;
  }
}

.box-items{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.box-item {
  position: relative;
}
.box-item > img{
  width: 100%;
  height: 672px;
}

.box-item > div{
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

.box-body{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: #ffffff;
}

.item1{
  width: 780px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  padding: 10px 0;
}

.item1 > div{
  padding: 0 100px 0 20px;
  border-left: 2px solid #797979;
}

.item1 > div:last-child{
  border-right: 2px solid #797979;
}

.item1 > div > div:first-child{
  font-size: 14px;
  margin-bottom: 5px;
}
.item1  > div > div:last-child{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 30px;
  margin-bottom: 10px;
}

.item1  > div > div:last-child > img{
  width: 36px;
  height: 36px;
  margin-right: 50px;
}

.division{
  width: 100%;
  border-bottom: 2px solid #767676;
}

.item2{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-weight: bold;
  margin-top: 40px;
}
.item2 > img:first-child{
  width: 115px;
  height: 112px;
}

.item2 > div{
  font-size: 20px;
  margin-top: 20px;
}
.item2 > div:last-child{
  font-size: 16px;
}

.item3{
  font-size: 16px;
  width: 1250px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 40px;
}

.item3 > div{
  width: 600px;
  text-align: left;
}

</style>
